/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import SectionCarouselWerkstattPictures from "views/SectionCarouselWerkstattPictures.js";
import SectionCarouselIBrettPictures from "views/SectionCarouselIBrettPictures.js";
// core components

function SectionHomeMain() {
  return (
    <>
      {/* <div className="section section-dark"> */}
      <div className="section section-gray">
      {/* <div > */}
        <Container>
          <Row>
            <Col md="7">
              <SectionCarouselWerkstattPictures/>
              <SectionCarouselIBrettPictures/>
            </Col>


            <Col className="ml-auto mr-auto text-center" md="5">
              <h3 className="title">  <b> Komplexe Instandhaltung nach ELA1 </b></h3>
              <h5>
                <b>
              Wartung & Reparaturen,
              </b>
              </h5>
              
              <h5>
                <b>
Servicearbeiten an allen Motoren,
</b>
              </h5>
              
              <h5>
                <b>
Instrumentierung, I-Bretter und Überholung der elektrischen Anlagen,
</b>
              </h5>
              
              <h5>
                <b>
Anbau von Winglets und anderen Komponenten,
</b>
              </h5>
              
              <h5>
                <b>
Flugleistungs- und Wettbewerbstuning,
</b>
              </h5>
              
              <h5>
                <b>
Kunststofftechnik,
</b>
              </h5>
              
              <h5>
                <b>
Bauteile aus Faserverbundwerkstoffen in der ganzen Vielfalt,
</b>
              </h5>
              
              <h5>
                <b>
Sichtcarbon- und Designparts,
</b>
              </h5>
              
              <h5>
                <b>
Hochleistungsfaserverbundwerkstoffe,
</b>
              </h5>
              
              <h5>
                <b>

Konstruktion / Berechnung / Prozessmanagement,
</b>
              </h5>
              
              <h5>
                <b>

Formenbau und vieles mehr!
</b>
              </h5>
              
              <h5>
                <b>
Schulungen
            </b>
              </h5>
              <h3 className="title">  <b> ... alles mit Liebe</b></h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionHomeMain;
