/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

// core components

const items = [
  {
    src: require("assets/img/carousel/jps_22.jpg"),
    altText: "Wägung",
    caption: "Wägung",
  },
  {
    src: require("assets/img/carousel/jps_10.jpg"),
    altText: "Werkstatt",
    caption: "Werkstatt",
  },
  {
    src: require("assets/img/carousel/jps_11.jpg"),
    altText: "Motorenwartung",
    caption: "Motorenwartung",
  },
  {
    src: require("assets/img/carousel/jps_24.jpg"),
    altText: "Avionik-Prüfung",
    caption: "Avionik-Prüfung",
  },
  {
    src: require("assets/img/carousel/jps_29.jpg"),
    altText: "Hauben-Rohling aufziehen",
    caption: "Hauben-Rohling aufziehen",
  },

  {
    src: require("assets/img/carousel/jps_01.jpg"),
    altText: "Temper Kammer",
    caption: "Temper Kammer",
  },
  {
    src: require("assets/img/carousel/jps_15.jpg"),
    altText: "Somewhere",
    caption: "Somewhere",
  },
  {
    src: require("assets/img/carousel/jps_16.jpg"),
    altText: "GFK-Teil Fertigung",
    caption: "GFK-Teil Fertigung",
  },
  {
    src: require("assets/img/carousel/jps_18.jpg"),
    altText: "Holzflugzeug Foka 5",
    caption: "Holzflugzeug Foka 5",
  },
  {
    src: require("assets/img/carousel/jps_19.jpg"),
    altText: "Motorenwartung",
    caption: "Motorenwartung",
  },

  {
    src: require("assets/img/carousel/jps_28.jpg"),
    altText: "Hauben-Rohling aufziehen",
    caption: "Hauben-Rohling aufziehen",
  },
  {
    src: require("assets/img/carousel/jps_25.jpg"),
    altText: "Auch im Winter erreichbar",
    caption: "Auch im Winter erreichbar",
  },

];

function SectionCarousel() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section pt-o section-gray" id="carousel">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Card className="page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <CarouselCaption
                          captionText={item.caption}
                          captionHeader=""
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionCarousel;
