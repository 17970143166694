/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionDark() {
  return (
    <>
      {/* <div className="section section-dark"> */}
      <div className="section section-gray">
      {/* <div > */}
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Motorsegler- und Segelflugzeugservice</h2>
              {/* <p className="description">
              </p> */}
              <h3>
                 <b>    
                   Komplexe Instandhaltung nach ELA1, 
                   </b>
              </h3>
              <h3>
              <b> Kunststofftechnik, </b>
                </h3>
              <h3>
              <b> Schulungen,</b>
               </h3>
              <h3>
              <b> Bauteile aus Faserverbundwerkstoffen in der ganzen Vielfalt,</b>
                </h3>
              <h3>

              <b> Sichtcarbon- und Designparts, </b>
                </h3>
              <h3>

              <b> Servicearbeiten an allen Motoren,</b>
                </h3>
              <h3>

              <b>Anbau von Winglets und anderen Komponenten,</b>
                </h3>
              <h3>

              <b> Hochleistungsfaserverbundwerkstoffe,</b>
                </h3>
              <h3>

              <b> Konstruktion / Berechnung / Prozessmanagement</b>
                </h3>
              <h3>

              <b> Flugleistungs- und Wettbewerbstuning,</b>
                </h3>
              <h3>

              <b> Formenbau und vieles mehr!</b>
               
                </h3>


            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionDark;
